











import BillingModule from '@/store/modules/BillingModule';
import { IStripePlan } from '@/store/types/IStripePlan';
import { Component, Vue, PropSync } from 'vue-property-decorator';
@Component({ components: {} })
export default class PlanCurrencyPicker extends Vue {
  @PropSync('plan') selectedPlan!: IStripePlan;
  created() {
    this.selectedPlan = BillingModule.stripePlans.find(
      (p) => p.Currency === 'USD',
    )!;
  }
  setPlan(plan: IStripePlan) {
    this.selectedPlan = plan;
  }

  get stripePlans() {
    return BillingModule.stripePlans;
  }

  getVariant(plan: IStripePlan) {
    if (this.selectedPlan) {
      return plan.Currency == this.selectedPlan.Currency
        ? 'info'
        : 'light';
    }
  }
}
