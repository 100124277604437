










import { Component, Vue } from 'vue-property-decorator';
import createnewplan from './components/CreateNewPlan.vue';
import modifyexistingplan from './components/ModifyExistingPlan.vue';
import BillingModule from '@/store/modules/BillingModule';
import Logoloadingindicator from '@/components/logoloadingindicator.vue';

@Component({
  components: {
    createnewplan,
    modifyexistingplan,
    Logoloadingindicator,
  },
})
export default class BillingPage extends Vue {
  get hasSubscription() {
    return BillingModule.hasSubscription;
  }
  get isLoading() {
    return BillingModule.isLoading;
  }
}
