


































import * as api from '@/store/api';
import { Component, Vue } from 'vue-property-decorator';
import BillingModule from '@/store/modules/BillingModule';
@Component
export default class ModifyExistingPlan extends Vue {
  // https://stripe.com/docs/billing/subscriptions/checkout
  // portal settings are here
  // https://dashboard.stripe.com/test/settings/billing/portal
  async launchPortal() {
    var portalSession = await api.createPortalSession();
    console.log(portalSession);
    window.location.href = portalSession.url;
  }

  get cancelatperiodend() {
    return BillingModule.cancelAtPeriodEnd;
  }
}
