































import { Component, Vue, PropSync } from 'vue-property-decorator';
@Component({ components: {} })
export default class PlanIntervalPicker extends Vue {
  @PropSync('planintervalyearly') planIntervalYearly!: boolean;

  mounted() {
    this.$root.$on('bv::tooltip::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'savetooltip') {
        setTimeout(() => {
          this.$root.$emit('bv::show::tooltip', 'btngrp');
        }, 1000);
      }
    });
  }

  togglePlan(value: boolean) {
    this.planIntervalYearly = value;
  }
  getVariant(yearlyPlan: boolean) {
    return this.planIntervalYearly == yearlyPlan ? 'info' : 'light';
  }
}
