
































































































































import planintervalpicker from './PlanIntervalPicker.vue';
import plancurrencypicker from './PlanCurrencyPicker.vue';
import planfeature from './PlanFeature.vue';
import { loadStripe } from '@stripe/stripe-js';
import * as api from '@/store/api';
import { Component, Vue } from 'vue-property-decorator';
import { IStripePlan } from '@/store/types/IStripePlan';

@Component({
  components: {
    planintervalpicker,
    plancurrencypicker,
    planfeature,
  },
})
export default class CreateNewPlan extends Vue {
  planIntervalYearly = true;
  selectedPlan: IStripePlan | null = null;
  get Currency() {
    return this.selectedPlan?.Currency;
  }
  get CurrencySymbol() {
    return this.selectedPlan?.CurrencySymbol;
  }
  get PlanPriceId() {
    return this.planIntervalYearly
      ? this.selectedPlan!.YearlyPlanPriceId
      : this.selectedPlan!.MonthlyPlanPriceId;
  }
  get PlanAmount() {
    return this.planIntervalYearly
      ? this.selectedPlan?.YearlyPlanAmount
      : this.selectedPlan?.MonthlyPlanAmount;
  }

  // https://stripe.com/docs/billing/subscriptions/checkout
  async launchCheckout() {
    var checkoutSession = await api.createCheckoutSession(
      this.PlanPriceId,
    );

    const stripe = await loadStripe(
      process.env.VUE_APP_STRIPE_PUBLISHABLEKEY!,
    );

    // Call Stripe.js method to redirect to the new Checkout page
    // https://stripe.com/docs/js/checkout/redirect_to_checkout
    var result = await stripe!.redirectToCheckout({
      sessionId: checkoutSession.sessionId,
    });
    console.log(result);
  }
}
